import { FormFieldContext, FormFieldContextState } from 'atomic/legacy/obj.form'
import * as React from 'react'
import {
  FloatingLabelChildrenNew,
  FloatingLabelStyledNew,
  FloatingLabelWrapperStyledNew
} from './floating-label-new.component.style'

export interface FloatingLabelProps {
  label: string
}

export interface FloatingLabelState {
  value: string
}

export class FloatingLabelNew extends React.Component<FloatingLabelProps, FloatingLabelState> {
  render() {
    return (
      <FormFieldContext.Consumer>
        {(formFieldConsumer: FormFieldContextState) => {
          const val = formFieldConsumer && formFieldConsumer.value ? formFieldConsumer.value : ''

          return (
            <FloatingLabelStyledNew>
              <FloatingLabelChildrenNew>{this.props.children}</FloatingLabelChildrenNew>
              <FloatingLabelWrapperStyledNew filled={val && val !== ''}>
                {this.props.label}
              </FloatingLabelWrapperStyledNew>
            </FloatingLabelStyledNew>
          )
        }}
      </FormFieldContext.Consumer>
    )
  }
}
