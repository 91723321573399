// import { HttpPostContainer } from '@root/src/components/legacy/obj.http-container/http-post-container.component'
import IndexLayout from '@root/src/components/org.layout/layout.component'
import DoctorRegister from '@root/src/modules/medical-register'
import DoctorRegisterNew from '@root/src/modules/medical-register-new'
import { graphql } from 'gatsby'

import React from 'react'

const MedicalRegister = props => {
  return (
    <IndexLayout location={props.location}>
      {process.env.GATSBY_COSMIC_BUCKET.toLocaleLowerCase() !== 'weinmann' ? (
        <DoctorRegister {...props} />
      ) : (
        <DoctorRegisterNew {...props} />
      )}
    </IndexLayout>
  )
}

export default MedicalRegister

export const query = graphql`
  query DoctorRegister {
    site {
      siteMetadata {
        siteUrl
      }
    }

    allCosmicjsEspecialidades(sort: { fields: title }) {
      edges {
        node {
          id
          slug
          title
        }
      }
    }

    informacoesContato: cosmicjsInformacoesEstaticas(slug: { eq: "informacoes-de-contato" }) {
      metadata {
        telefoneContato
        label_telefone
        horarioAtendimento
      }
    }

    cosmicjsInformacoesEstaticas(slug: { eq: "cadastro-medico" }) {
      title
      metadata {
        col2Titulo
        col2Conteudo
        botaoEnviarTexto
        emailDestino
        emailIntroMsg
        seo {
          titulo
          descricao
          imagem {
            url
          }
          imagemAlternativa
        }
        medico_ja_cadastrado_texto
        medico_ja_cadastrado_botao
        contact_area {
          title
          subtitle
          phone_title
          horario_de_atendimento_titulo
          image {
            url
            imgix_url
          }
        }
      }
    }
  }
`
