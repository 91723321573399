import styled from 'styled-components'
import { Color, FontSize, InputPlaceholder, Spacing } from 'atomic'
import { FontWeight } from 'atomic/legacy/obj.constants'

interface InputLabelProps {
  filled: boolean
}

export const FloatingLabelChildrenNew = styled.span``

export const FloatingLabelWrapperStyledNew = styled(InputPlaceholder)`
  position: absolute;
  top: ${(props: InputLabelProps) => (props.filled ? '10px' : '32px')};
  background-color: white;
  padding: 0 4px;
  left: 12px;
  transition: all 0.3s;
  font-weight: ${(props: InputLabelProps) =>
    props.filled ? FontWeight.SemiBold : FontWeight.Normal};
  font-size: ${(props: InputLabelProps) => (props.filled ? FontSize.XXSmall : FontSize.Small)};
  color: ${(props: InputLabelProps) => (props.filled ? Color.GrayDark : Color.Gray)};

  ${FloatingLabelChildrenNew}:focus-within ~ & {
    color: ${props => props.theme.color.primary};
    font-weight: ${FontWeight.SemiBold};
    font-size: ${FontSize.XXSmall};
    top: 10px;
  }
`

export const FloatingLabelStyledNew = styled.div`
  position: relative;
  padding-top: ${Spacing.Medium};
`
