import axios from '@root/src/utils/base-axios.js'

export interface Medico {
  idPessoaFisica: number
  idProfissionalSaude: number
  cpf: string
  rg: string
  titulo: string
  nome: string
  sobrenome: string
  complementoNome: null
  nomeCompleto: string
  dataNascimento: string
  conselhoRegional: string
  numeroCertificado: string
  ufCertificado: string
  status: string
  idUsuarioFleury: number
  profissionalSaudeFleury: string
}

export class CpfMedicoDataSource {
  static getCpf = async (cpf: string) => {
    cpf = cpf.replace(/\./g, '')
    cpf = cpf.replace(/-/g, '')

    const response = await axios.get(`/cadastro-medico/v1/medico?cpf=${cpf}`)

    return response.status === 200 ? response.data : null
  }
}
