export class StatesDataSource {
  static getStates = () => [
    { value: 'AC', name: 'Acre - AC' },
    { value: 'AL', name: 'Alagoas - AL' },
    { value: 'AM', name: 'Amazonas - AM' },
    { value: 'AP', name: 'Amapá - AP' },
    { value: 'BA', name: 'Bahia - BA' },
    { value: 'CE', name: 'Ceará - CE' },
    { value: 'DF', name: 'Distrito Federal - DF' },
    { value: 'ES', name: 'Espírito Santo - ES' },
    { value: 'GO', name: 'Goiás - GO' },
    { value: 'MA', name: 'Maranhão - MA' },
    { value: 'MG', name: 'Minas Gerais - MG' },
    { value: 'MS', name: 'Mato Grosso do Sul - MS' },
    { value: 'MT', name: 'Mato Grosso - MT' },
    { value: 'PA', name: 'Pará - PA' },
    { value: 'PB', name: 'Paraíba - PB' },
    { value: 'PE', name: 'Pernambuco - PE' },
    { value: 'PI', name: 'Piauí - PI' },
    { value: 'PR', name: 'Paraná - PR' },
    { value: 'RJ', name: 'Rio de Janeiro - RJ' },
    { value: 'RN', name: 'Rio Grande do Norte - RN' },
    { value: 'RO', name: 'Rondônia - RO' },
    { value: 'RR', name: 'Roraima - RR' },
    { value: 'RS', name: 'Rio Grande do Sul - RS' },
    { value: 'SC', name: 'Santa Catarina - SC' },
    { value: 'SE', name: 'Sergipe - SE' },
    { value: 'SP', name: 'São Paulo - SP' },
    { value: 'TO', name: 'Tocantins - TO' }
  ]
}
