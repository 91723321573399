import axios from 'axios'

export interface ViaCepData {
  cep: string
  logradouro: string
  complemento: string
  bairro: string
  localidade: string
  uf: string
  unidade: string
  ibge: string
  gia: string
}

export class ViaCepDataSource {
  static getCep = async (cep: string) => {
    /**
     * Give a only number cep string
     * https://viacep.com.br/
     */
    const instance = axios.create({
      baseURL: 'https://viacep.com.br'
    })
    const response = await instance.get<ViaCepData>(`/ws/${cep}/json`)
    return response.data
  }
}
