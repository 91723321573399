import { SEO } from '@root/src/components/legacy/mol.seo/seo.component'
import flashDispatcherService from '@root/src/components/legacy/obj.flash-wrapper/flash-dispatcher.service'
// import { HttpPostContainer } from '@root/src/components/legacy/obj.http-container/http-post-container.component'

import TitleWithBreadcrumbRow from '@root/src/components/org.title-with-breadcrumb-row/title-with-breadcrumb-row.component'
import { Query } from '@root/src/data/graphql/graphql-types'

// import {
//   buildSendEmailRequest,
//   sendEmailUrl
// } from '@root/src/data/http/request-builder/send-email.request'
import { StatesDataSource } from '@root/src/data/states.datasource'
import { ViaCepDataSource } from '@root/src/data/via-cep.datasource'
import { CpfMedicoDataSource } from '@root/src/data/cpf-medico.datasource'
// import { buildEmailHTML, EmailTopic } from '@root/src/utils/email-html'
import { PageProps } from '@root/src/utils/local-types'
import { appPaths, getCanonicalUrl } from 'utils/path'
import { Button, Col, Grid, H2, Row, Separator } from 'atomic'
import {
  EnhancedSelect,
  SelectOption
} from 'atomic/legacy/atm.enhanced-select/enhanced-select.component'

import { FloatingLabel } from 'atomic/legacy/atm.floating-label/floating-label.component'
import { TextField } from 'atomic/legacy/atm.text-field'
import { Form, FormData, Validators } from 'atomic/legacy/obj.form'
import { useTimestamp } from 'atomic/obj.custom-hooks/timestamp.hook'
import { graphql } from 'gatsby'
import { AlreadyRegisterModal } from '../../components/mol.already-register-modal/already-register-modal.component'
import { navigate } from '@reach/router'
import React, { useState } from 'react'

import axios from '@root/src/utils/base-axios.js'

import AttentionIcon from './AttentionIcon.svg'
import CryptoJS from 'crypto-js'

import styled from 'styled-components'

export const AdviceCrmRj = styled.div`
  background-color: #fef6e7;
  width: 100%;
  padding: 12px;
  border-radius: 8px;
  margin-bottom: 10px;
  span {
    font-family: 'ASAP';
    font-size: 12px;
    line-height: 18px;
    color: #595959;
    padding: 3px;
  }

  img {
    padding-top: 3px;
  }
`
interface DoctorRegisterProps {}

interface DoctorRegisterFormData {
  treatmentPronoun: SelectOption
  name: string
  rg: string
  cpf: string
  birthDate: string
  specialty: SelectOption
  crm: string
  crmState: SelectOption
  email: string
  phone: string
  postalCode: string
  state: SelectOption
  city: string
  commercialAddress: string
  addressNumber: string
  addressSuplement: string
  addressDistrict: string
}

interface PageData {
  title: string
  col2Title: string
  col2HTMLContent: string
  sendButtonText: string
  emailTo: string
  emailIntro: string
  medico_ja_cadastrado_texto: string
  medico_ja_cadastrado_botao: string
}

interface AddressInfo {
  city?: string
  uf?: string
  streetAddress?: string
  complement?: string
  neighborhood?: string
}
const treatmentPronoun = [
  {
    value: 'Dra',
    label: 'Título'
  },
  {
    value: 'Dr',
    label: 'Título'
  }
]
const DoctorRegister: React.FunctionComponent<PageProps<DoctorRegisterProps, Query>> = props => {
  const [cepTimestamp, updateCepTimestamp] = useTimestamp() as [number, () => void]
  const [formTimestamp, updateFormTimestamp] = useTimestamp() as [number, () => void]
  const [stateCrmSelected, setStateCrmSelected] = useState()
  const [addressInfo, setAddressInfo] = useState<AddressInfo>({
    city: '',
    uf: '',
    streetAddress: '',
    complement: '',
    neighborhood: ''
  })

  const [showModalAlreadyRegister, setShowModalAlreadyRegister] = useState(false)

  const cosmicEspecialidades = props.data.allCosmicjsEspecialidades.edges
  const cosmicStaticInfo = props.data.cosmicjsInformacoesEstaticas
  const seoData = cosmicStaticInfo.metadata.seo
  const states = StatesDataSource.getStates()

  const handleFormSuccess = () => {
    flashDispatcherService.dispatchMessage(
      'Seu cadastro foi efetuado com sucesso. Em breve enviaremos um link para cadastro de senha.',
      'success'
    )
    setAddressInfo({})
    updateFormTimestamp()
    updateCepTimestamp()
  }

  const specialtyOptions: SelectOption[] = (cosmicEspecialidades || []).map(item => ({
    value: item.node.title,
    label: item.node.title
  }))

  const stateOptions: SelectOption[] = states.map(item => ({
    value: item.value,
    label: item.name
  }))
  const pronounsOptions: SelectOption[] = treatmentPronoun.map(item => ({
    value: item.value,
    label: item.value
  }))
  const handleCepChange = async (value: string) => {
    if (Validators.ZipCodeRegex('').validationFn(value)) {
      const cepData = await ViaCepDataSource.getCep(value)
      if (cepData) {
        setAddressInfo({
          city: cepData.localidade || '',
          uf: cepData.uf || '',
          streetAddress: cepData.logradouro || '',
          complement: cepData.complemento || '',
          neighborhood: cepData.bairro || ''
        })
        updateCepTimestamp()
      }
    }
  }

  const handleCpfChange = async (value: string) => {
    const validate = /^(([0-9]{3}.[0-9]{3}.[0-9]{3}-[0-9]{2}))$/

    if (validate.test(value)) {
      const cpfData = await CpfMedicoDataSource.getCpf(value)
      const isRegisteredMedic = CryptoJS.AES.decrypt(
        cpfData.data,
        'RCMxMjMyMDEyRkxFVVJZUDRzc1dAb3Jk'
      ).toString(CryptoJS.enc.Utf8)

      if (isRegisteredMedic === 'true') {
        setShowModalAlreadyRegister(true)
      }
    }
  }

  const pageData: PageData = {
    title: cosmicStaticInfo.title,
    col2Title: cosmicStaticInfo.metadata.col2Titulo,
    col2HTMLContent: cosmicStaticInfo.metadata.col2Conteudo,
    sendButtonText: cosmicStaticInfo.metadata.botaoEnviarTexto,
    emailTo: cosmicStaticInfo.metadata.emailDestino,
    emailIntro: cosmicStaticInfo.metadata.emailIntroMsg,
    medico_ja_cadastrado_botao: cosmicStaticInfo.metadata.medico_ja_cadastrado_botao,
    medico_ja_cadastrado_texto: cosmicStaticInfo.metadata.medico_ja_cadastrado_texto
  }

  const breadcrumbAdditionalDictionary = {}
  breadcrumbAdditionalDictionary[appPaths.doctorRegister.path.doctorUrl] = pageData.title
  const siteUrl = props.data.site.siteMetadata.siteUrl

  const [loading, setLoading] = useState(false)

  const handleSubmit = async (formData: FormData<DoctorRegisterFormData>) => {
    if (Object.keys(formData.error).length !== 0) {
      return
    }

    const formdata = formData.data
    const data = {
      marca: process.env.GATSBY_MARCA,
      titulo: formdata.treatmentPronoun.value,
      nome: formdata.name,
      dataNascimento: formdata.birthDate,
      rg: formdata.rg,
      cpf: formdata.cpf,
      especialidade: formdata.specialty.value,
      crm: formdata.crm,
      email: formdata.email,
      telefone: formdata.phone,
      endereco: formdata.commercialAddress,
      numero: formdata.addressNumber,
      complemento: formdata.addressSuplement || '',
      cep: formdata.postalCode,
      bairro: formdata.addressDistrict,
      cidade: formdata.city,
      estado: formdata.state.value
    }

    setLoading(true)

    const url = `/cadastro-medico/v1/medico`

    // const url =
    //   process.env.NODE_ENV === 'production'
    //     ? 'https://gjn53fj1a5.execute-api.us-east-1.amazonaws.com/prd/medico'
    //     : 'https://opk3f7x17a.execute-api.us-east-1.amazonaws.com/hml/medico'

    try {
      const response = await axios.post(url, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })

      if (response.data.statusCode === 200) {
        handleFormSuccess()
      } else {
        throw ''
      }
    } catch (e) {
      flashDispatcherService.dispatchMessage(
        'Houve falha ao enviar formulario, tente novamente.',
        'alert'
      )
    }

    setLoading(false)
  }
  return (
    <>
      <AlreadyRegisterModal
        open={showModalAlreadyRegister}
        onClose={async () => {
          await navigate('/')
          await navigate('/medico/cadastro-medico')
        }}
        buttonText={pageData.medico_ja_cadastrado_botao}
        messageText={pageData.medico_ja_cadastrado_texto}
      />
      <SEO
        socialMedia={{
          canonicalUrl: getCanonicalUrl(siteUrl, appPaths.doctorRegister.path),
          title: seoData.titulo,
          image: seoData.imagem.url,
          imageAlt: seoData.imagemAlt,
          description: seoData.descricao
        }}
      />
      <Grid>
        <TitleWithBreadcrumbRow
          title={pageData.title}
          addtionalDictionary={breadcrumbAdditionalDictionary}
        />

        <Row>
          <Col xs={12} sm={12} md={7} lg={7}>
            <Separator />

            <Form id="form-cadastro-medico" onSubmit={handleSubmit} key={formTimestamp}>
              <Row mb center="xs">
                <Col xs={12} md={4}>
                  <Form.Field
                    name="treatmentPronoun"
                    validators={[Validators.Required('Campo obrigatório')]}
                  >
                    <FloatingLabel label={'Título'}>
                      <EnhancedSelect options={pronounsOptions} />
                    </FloatingLabel>
                  </Form.Field>
                  <Separator />
                </Col>
                <Col xs={12} md={8}>
                  <Form.Field
                    name="name"
                    validators={[
                      Validators.Required('Campo obrigatório'),
                      Validators.IsFullName('Por favor, digite o nome completo')
                    ]}
                  >
                    <FloatingLabel label={'Nome completo'}>
                      <TextField id="text-field-medic-name" type={'text'} autoComplete="name" />
                    </FloatingLabel>
                  </Form.Field>
                  <Separator />
                </Col>
              </Row>
              <Row mb center="xs">
                <Col xs={12} md={4}>
                  <Form.Field
                    name="rg"
                    validators={[
                      Validators.Required('Campo obrigatório'),
                      Validators.IsNotOnlySpaces('Valor invalido')
                    ]}
                  >
                    <FloatingLabel label={'RG'}>
                      <TextField
                        id="text-field-medic-rg"
                        type={'text'}
                        kind="custom"
                        options={{ mask: 'SSSSSSSSSSSSSS' }}
                      />
                    </FloatingLabel>
                  </Form.Field>
                  <Separator />
                </Col>
                <Col xs={12} md={4}>
                  <Form.Field
                    name="cpf"
                    validators={[
                      Validators.Required('Campo obrigatório'),
                      Validators.IsNotOnlySpaces('Valor invalido'),
                      Validators.CpfRegex('O CPF preenchido não é válido')
                    ]}
                  >
                    <FloatingLabel label={'CPF'}>
                      <TextField
                        id="text-field-medic-cpf"
                        type={'text'}
                        kind="cpf"
                        onValueChange={handleCpfChange}
                      />
                    </FloatingLabel>
                  </Form.Field>
                  <Separator />
                </Col>
                <Col xs={12} md={4}>
                  <Form.Field
                    name="birthDate"
                    validators={[
                      Validators.Required('Campo obrigatório'),
                      Validators.IsNotOnlySpaces('Valor invalido'),
                      Validators.DateRegex('Insira uma data válida')
                    ]}
                  >
                    <FloatingLabel label={'Data de Nascimento'}>
                      <TextField
                        id="text-field-medic-birth"
                        kind="custom"
                        options={{ mask: '99/99/9999' }}
                        autoComplete={'bday'}
                      />
                    </FloatingLabel>
                  </Form.Field>
                  <Separator />
                </Col>
              </Row>
              <Row mb center="xs">
                <Col xs={12} md={12}>
                  <Form.Field
                    name="specialty"
                    validators={[Validators.Required('Campo obrigatório')]}
                  >
                    <FloatingLabel label={'Especialidade'}>
                      <EnhancedSelect options={specialtyOptions} />
                    </FloatingLabel>
                  </Form.Field>
                  <Separator />
                </Col>
              </Row>

              <Row mb center="xs">
                {stateCrmSelected === 'RJ' && (
                  <Col xs={12}>
                    <AdviceCrmRj>
                      <Row mb center="xs">
                        <Col xs={1}>
                          <img src={AttentionIcon} alt="atenção" />
                        </Col>
                        <Col xs={11}>
                          <span>
                            Adicione “52” na frente do CRM e remova o primeiro zero, se houver. Se o
                            seu CRM já começa com “52”, é só digitar normalmente
                          </span>
                        </Col>
                      </Row>
                    </AdviceCrmRj>
                  </Col>
                )}
                <Col xs={5}>
                  <Form.Field
                    name="crmState"
                    validators={[Validators.Required('Campo obrigatório')]}
                  >
                    <FloatingLabel label={'UF CRM'}>
                      <EnhancedSelect
                        options={stateOptions}
                        onChange={e => setStateCrmSelected(e.value)}
                      />
                    </FloatingLabel>
                  </Form.Field>
                  <Separator />
                </Col>
                <Col xs={7}>
                  <Form.Field
                    name="crm"
                    validators={[
                      Validators.Required('Campo obrigatório'),
                      Validators.IsValidCrmRj(
                        stateCrmSelected,
                        'CRM inválido. Remova o primeiro zero'
                      ),
                      Validators.IsNotOnlySpaces('Valor invalido')
                    ]}
                  >
                    <FloatingLabel label={'CRM'}>
                      <TextField id="text-field-medic-crn" type={'text'} />
                    </FloatingLabel>
                  </Form.Field>
                  <Separator />
                </Col>
              </Row>

              <Row mb center="xs">
                <Col xs={12} md={8}>
                  <Form.Field
                    name="email"
                    validators={[
                      Validators.Required('Campo obrigatório'),
                      Validators.IsNotOnlySpaces('Valor invalido'),
                      Validators.EmailRegex('O e-mail preenchido não é válido')
                    ]}
                  >
                    <FloatingLabel label={'E-mail'}>
                      <TextField id="text-field-medic-email" type={'email'} autoComplete="email" />
                    </FloatingLabel>
                  </Form.Field>

                  <Separator />
                </Col>
                <Col xs={12} md={4}>
                  <Form.Field
                    name="phone"
                    validators={[
                      Validators.Required('Campo obrigatório'),
                      Validators.IsNotOnlySpaces('Valor invalido'),
                      Validators.PhoneRegex('O telefone preenchido não é válido')
                    ]}
                  >
                    <FloatingLabel label={'Telefone'}>
                      <TextField
                        id="text-field-medic-phone"
                        type={'tel'}
                        kind={'cel-phone'}
                        autoComplete="tel"
                      />
                    </FloatingLabel>
                  </Form.Field>
                  <Separator />
                </Col>
              </Row>

              <Row mb center="xs">
                <Col xs={12} md={3}>
                  <Form.Field
                    name="postalCode"
                    validators={[
                      Validators.Required('Campo obrigatório'),
                      Validators.IsNotOnlySpaces('Valor invalido')
                    ]}
                  >
                    <FloatingLabel label={'CEP'}>
                      <TextField
                        id="text-field-medic-cep"
                        type={'text'}
                        kind={'zip-code'}
                        autoComplete="postal-code"
                        onValueChange={handleCepChange}
                      />
                    </FloatingLabel>
                  </Form.Field>
                  <Separator />
                </Col>
                <Col xs={8} md={5}>
                  <Form.Field
                    name="city"
                    initialValue={addressInfo.city}
                    key={cepTimestamp}
                    validators={[
                      Validators.Required('Campo obrigatório'),
                      Validators.IsNotOnlySpaces('Valor invalido')
                    ]}
                  >
                    <FloatingLabel label={'Cidade'}>
                      <TextField
                        id="text-field-medic-city"
                        type={'text'}
                        autoComplete="address-level2"
                      />
                    </FloatingLabel>
                  </Form.Field>
                  <Separator />
                </Col>
                <Col xs={4} md={4}>
                  <Form.Field
                    name="state"
                    initialValue={stateOptions.find(item => item.value === addressInfo.uf)}
                    key={cepTimestamp}
                    validators={[Validators.Required('Campo obrigatório')]}
                  >
                    <FloatingLabel label={'UF'}>
                      <EnhancedSelect options={stateOptions} />
                    </FloatingLabel>
                  </Form.Field>
                  <Separator />
                </Col>
              </Row>

              <Row mb center="xs">
                <Col xs={8} md={10}>
                  <Form.Field
                    name="commercialAddress"
                    initialValue={addressInfo.streetAddress}
                    key={cepTimestamp}
                    validators={[
                      Validators.Required('Campo obrigatório'),
                      Validators.IsNotOnlySpaces('Valor invalido')
                    ]}
                  >
                    <FloatingLabel label={'Endereço Comercial'}>
                      <TextField
                        id="text-field-medic-address"
                        type={'text'}
                        autoComplete={'street-address'}
                      />
                    </FloatingLabel>
                  </Form.Field>
                  <Separator />
                </Col>
                <Col xs={4} md={2}>
                  <Form.Field
                    name="addressNumber"
                    validators={[
                      Validators.Required('Campo obrigatório'),
                      Validators.IsNotOnlySpaces('Valor invalido')
                    ]}
                  >
                    <FloatingLabel label={'Número'}>
                      <TextField id="text-field-medic-number" type={'text'} />
                    </FloatingLabel>
                  </Form.Field>
                  <Separator />
                </Col>
              </Row>
              <Row mb center="xs">
                <Col xs={4} md={6}>
                  <Form.Field
                    name="addressSuplement"
                    initialValue={addressInfo.complement}
                    key={cepTimestamp}
                  >
                    <FloatingLabel label={'Complemento'}>
                      <TextField id="text-field-medic-complement" type={'text'} />
                    </FloatingLabel>
                  </Form.Field>
                  <Separator />
                </Col>
                <Col xs={8} md={6}>
                  <Form.Field
                    name="addressDistrict"
                    initialValue={addressInfo.neighborhood}
                    key={cepTimestamp}
                    validators={[
                      Validators.Required('Campo obrigatório'),
                      Validators.IsNotOnlySpaces('Valor invalido')
                    ]}
                  >
                    <FloatingLabel label={'Bairro'}>
                      <TextField id="text-field-medic-neighborhood" type={'text'} />
                    </FloatingLabel>
                  </Form.Field>
                  <Separator />
                </Col>
              </Row>

              <Row mb center="xs">
                <Col xs={12} sm={12} md={4} lg={4}>
                  <Button
                    id={`button-submit-medic-register`}
                    type="submit"
                    kind="primary"
                    loading={loading}
                    expanded
                  >
                    {pageData.sendButtonText}
                  </Button>
                  <Separator />
                </Col>
              </Row>
            </Form>
          </Col>
          <Col xs={12} md={4} mdOffset={1} lg={4}>
            <H2>Cadastre-se</H2>
            <div
              dangerouslySetInnerHTML={{
                __html: pageData.col2HTMLContent
              }}
            />
            <Separator />
          </Col>
        </Row>
      </Grid>
    </>
  )
}

export default DoctorRegister

const getEmailTopics = (data: DoctorRegisterFormData) => [
  { title: 'Pronome de Tratamento', content: data.treatmentPronoun.label },
  { title: 'Nome', content: data.name },
  { title: 'Data de Nascimento', content: data.birthDate },
  { title: 'RG', content: data.rg },
  { title: 'CPF', content: data.cpf },
  { title: 'Especialidade', content: data.specialty.value },
  { title: 'CRM', content: `${data.crm} - ${data.crmState.value}` },
  { title: 'Email', content: data.email },
  { title: 'Telefone', content: data.phone },
  { title: 'CEP', content: data.postalCode },
  { title: 'Estado', content: data.state.value },
  { title: 'Cidade', content: data.city },
  { title: 'Endereço', content: data.commercialAddress },
  { title: 'Numero', content: data.addressNumber },
  { title: 'Complemento', content: data.addressSuplement }
]
