import { SEO } from '@root/src/components/legacy/mol.seo/seo.component'
import flashDispatcherService from '@root/src/components/legacy/obj.flash-wrapper/flash-dispatcher.service'

import TitleWithBreadcrumbRow from '@root/src/components/org.title-with-breadcrumb-row/title-with-breadcrumb-row.component'
import { Query } from '@root/src/data/graphql/graphql-types'
import { StatesDataSource } from '@root/src/data/states.datasource'
import { ViaCepDataSource } from '@root/src/data/via-cep.datasource'
import { CpfMedicoDataSource } from '@root/src/data/cpf-medico.datasource'

import { PageProps } from '@root/src/utils/local-types'
import { appPaths, getCanonicalUrl } from 'utils/path'
import { Button, Separator } from 'atomic'
import {
  EnhancedSelectNew,
  SelectOption
} from 'atomic/legacy/atm.enhanced-select-new/enhanced-select-new.component'

import { FloatingLabelNew } from 'atomic/legacy/atm.floating-label-new/floating-label-new.component'

import { Form, FormData, Validators } from 'atomic/legacy/obj.form'
import { useTimestamp } from 'atomic/obj.custom-hooks/timestamp.hook'
import Title from 'site/src/components/Title'
import { AlreadyRegisterModal } from '../../components/mol.already-register-modal/already-register-modal.component'
import { navigate } from '@reach/router'
import React, { useState } from 'react'

import axios from '@root/src/utils/base-axios.js'

import AttentionIcon from './AttentionIcon.svg'

import styled from 'styled-components'
import { TextFieldNew } from '@root/../atomic/legacy/atm.text-field-new'
import { Grid, Container } from '@material-ui/core'
import iconAtendente from '@root/src/assets/img/iconAtendente.svg'
import CryptoJS from 'crypto-js'

export const AdviceCrmRj = styled.div`
  background-color: #fef6e7;
  width: 100%;
  padding: 12px;
  border-radius: 8px;
  margin-bottom: 10px;

  span {
    font-family: 'Signika';
    font-size: 12px;
    line-height: 18px;
    color: #595959;
    padding: 3px;
  }

  img {
    padding-top: 3px;
  }
`

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`

export const ImageSidebarContainer = styled.div`
  .frame1 {
    width: 60px;
    height: 60px;
    background: ${props => props.theme.color.primary};
    opacity: 0.2;
    position: relative;
    z-index: 0;
  }
  .frame2 {
    width: 30px;
    height: 30px;
    background: ${props => props.theme.color.primary};
    opacity: 0.2;
    margin-top: -50px;
    margin-left: 10px;
    position: relative;
    z-index: 2;
  }
  img {
    width: 250px;
    height: 350px;
    object-fit: contain;
    background: linear-gradient(0deg, #f1f1f1, #f1f1f1);
    margin-left: 20px;
    margin-top: -20px;
    position: relative;
    z-index: 0;
  }
  .frame3 {
    width: 60px;
    height: 60px;
    background: ${props => props.theme.color.primary};
    opacity: 0.2;
    margin-top: -40px;
    margin-left: 230px;
    position: relative;
    z-index: -1;
  }
  .frame4 {
    width: 30px;
    height: 30px;
    background: ${props => props.theme.color.primary};
    opacity: 0.2;
    position: relative;
    z-index: 0;
    margin-top: -43px;
    margin-left: 250px;
  }
`

export const ContactSidebar = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  font-family: Signika;

  .title {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
    color: #373a3a;
  }
  .subtitle {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 24px;
    color: #373a3a;
  }

  .contact {
    img {
      float: left;
      padding: 3px;
    }

    .contact-title {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: #373a3a;
    }

    .contact-number {
      font-size: 20px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: ${props => props.theme.color.primary};
    }
  }

  .hour-title {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 24px;
    color: #373a3a;
  }

  .hour-description {
    margin-top: 10px;
    p {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 5px;
      color: #373a3a;
    }
  }
`

interface DoctorRegisterProps {}

interface DoctorRegisterFormData {
  treatmentPronoun: SelectOption
  name: string
  rg: string
  cpf: string
  birthDate: string
  specialty: SelectOption
  crm: string
  crmState: SelectOption
  email: string
  phone: string
  postalCode: string
  state: SelectOption
  city: string
  commercialAddress: string
  addressNumber: string
  addressSuplement: string
  addressDistrict: string
}

interface PageData {
  title: string
  col2Title: string
  col2HTMLContent: string
  sendButtonText: string
  emailTo: string
  emailIntro: string
  medico_ja_cadastrado_texto: string
  medico_ja_cadastrado_botao: string
}

interface AddressInfo {
  city?: string
  uf?: string
  streetAddress?: string
  complement?: string
  neighborhood?: string
}
const treatmentPronoun = [
  {
    value: 'Dra',
    label: 'Título'
  },
  {
    value: 'Dr',
    label: 'Título'
  }
]
const DoctorRegisterNew: React.FunctionComponent<PageProps<DoctorRegisterProps, Query>> = props => {
  const [cepTimestamp, updateCepTimestamp] = useTimestamp() as [number, () => void]
  const [formTimestamp, updateFormTimestamp] = useTimestamp() as [number, () => void]
  const [stateCrmSelected, setStateCrmSelected] = useState()
  const [addressInfo, setAddressInfo] = useState<AddressInfo>({
    city: '',
    uf: '',
    streetAddress: '',
    complement: '',
    neighborhood: ''
  })

  const [showModalAlreadyRegister, setShowModalAlreadyRegister] = useState(false)

  const cosmicEspecialidades = props.data?.allCosmicjsEspecialidades.edges
  const cosmicInfosContato = props.data.informacoesContato.metadata
  const cosmicStaticInfo = props.data.cosmicjsInformacoesEstaticas
  const seoData = cosmicStaticInfo.metadata.seo
  const states = StatesDataSource.getStates()

  const handleFormSuccess = () => {
    flashDispatcherService.dispatchMessage(
      'Seu cadastro foi efetuado com sucesso. Em breve enviaremos um link para cadastro de senha.',
      'success'
    )
    setAddressInfo({})
    updateFormTimestamp()
    updateCepTimestamp()
  }

  const specialtyOptions: SelectOption[] = (cosmicEspecialidades || []).map(item => ({
    value: item.node.title,
    label: item.node.title
  }))

  const stateOptions: SelectOption[] = states.map(item => ({
    value: item.value,
    label: item.name
  }))
  const pronounsOptions: SelectOption[] = treatmentPronoun.map(item => ({
    value: item.value,
    label: item.value
  }))
  const handleCepChange = async (value: string) => {
    if (Validators.ZipCodeRegex('').validationFn(value)) {
      const cepData = await ViaCepDataSource.getCep(value)
      if (cepData) {
        setAddressInfo({
          city: cepData.localidade || '',
          uf: cepData.uf || '',
          streetAddress: cepData.logradouro || '',
          complement: cepData.complemento || '',
          neighborhood: cepData.bairro || ''
        })
        updateCepTimestamp()
      }
    }
  }

  const handleCpfChange = async (value: string) => {
    const validate = /^(([0-9]{3}.[0-9]{3}.[0-9]{3}-[0-9]{2}))$/

    if (validate.test(value)) {
      const cpfData = await CpfMedicoDataSource.getCpf(value)
      const isRegisteredMedic = CryptoJS.AES.decrypt(
        cpfData.data,
        'RCMxMjMyMDEyRkxFVVJZUDRzc1dAb3Jk'
      ).toString(CryptoJS.enc.Utf8)

      if (isRegisteredMedic === 'true') {
        setShowModalAlreadyRegister(true)
      }
    }
  }

  const pageData: PageData = {
    title: cosmicStaticInfo.title,
    col2Title: cosmicStaticInfo.metadata.col2Titulo,
    col2HTMLContent: cosmicStaticInfo.metadata.col2Conteudo,
    sendButtonText: cosmicStaticInfo.metadata.botaoEnviarTexto,
    emailTo: cosmicStaticInfo.metadata.emailDestino,
    emailIntro: cosmicStaticInfo.metadata.emailIntroMsg,
    medico_ja_cadastrado_botao: cosmicStaticInfo.metadata.medico_ja_cadastrado_botao,
    medico_ja_cadastrado_texto: cosmicStaticInfo.metadata.medico_ja_cadastrado_texto
  }

  const breadcrumbAdditionalDictionary = {}
  breadcrumbAdditionalDictionary[appPaths.doctorRegister.path.doctorUrl] = pageData.title
  const siteUrl = props.data.site.siteMetadata.siteUrl

  const [loading, setLoading] = useState(false)

  const handleSubmit = async (formData: FormData<DoctorRegisterFormData>) => {
    if (Object.keys(formData.error).length !== 0) {
      return
    }

    const formdata = formData.data
    const data = {
      marca: process.env.GATSBY_MARCA,
      titulo: formdata.treatmentPronoun.value,
      nome: formdata.name,
      dataNascimento: formdata.birthDate,
      rg: formdata.rg,
      cpf: formdata.cpf,
      especialidade: formdata.specialty.value,
      crm: formdata.crm,
      email: formdata.email,
      telefone: formdata.phone,
      endereco: formdata.commercialAddress,
      numero: formdata.addressNumber,
      complemento: formdata.addressSuplement || '',
      cep: formdata.postalCode,
      bairro: formdata.addressDistrict,
      cidade: formdata.city,
      estado: formdata.state.value
    }

    setLoading(true)

    // const url = process.env.GATSBY_API_REGISTER_MEDIC
    const url = `/cadastro-medico/v1/medico`

    // const url =
    //   process.env.NODE_ENV === 'production'
    //     ? 'https://gjn53fj1a5.execute-api.us-east-1.amazonaws.com/prd/medico'
    //     : 'https://opk3f7x17a.execute-api.us-east-1.amazonaws.com/hml/medico'

    try {
      const response = await axios.post(url, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })

      if (response.data.statusCode === 200) {
        handleFormSuccess()
      } else {
        throw ''
      }
    } catch (e) {
      flashDispatcherService.dispatchMessage(
        'Houve falha ao enviar formulario, tente novamente.',
        'alert'
      )
    }

    setLoading(false)
  }
  return (
    <>
      <AlreadyRegisterModal
        open={showModalAlreadyRegister}
        onClose={async () => {
          await navigate('/')
          await navigate('/medico/cadastro-medico')
        }}
        buttonText={pageData.medico_ja_cadastrado_botao}
        messageText={pageData.medico_ja_cadastrado_texto}
      />
      <SEO
        socialMedia={{
          canonicalUrl: getCanonicalUrl(siteUrl, appPaths.doctorRegister.path),
          title: seoData.titulo,
          image: seoData.imagem.url,
          imageAlt: seoData.imagemAlt,
          description: seoData.descricao
        }}
      />
      <Container maxWidth="md">
        <TitleWithBreadcrumbRow addtionalDictionary={breadcrumbAdditionalDictionary} title={''} />
        <Title variant="h5">{pageData.title}</Title>
        {/* <Separator /> */}
        <Grid container spacing={4}>
          <Grid item xs={12} sm={8}>
            <Form id="form-cadastro-medico" onSubmit={handleSubmit} key={formTimestamp}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <Form.Field
                    name="treatmentPronoun"
                    validators={[Validators.Required('Campo obrigatório')]}
                  >
                    <FloatingLabelNew label={'Título'}>
                      <EnhancedSelectNew options={pronounsOptions} />
                    </FloatingLabelNew>
                  </Form.Field>
                </Grid>
                <Grid item xs={12} md={8}>
                  <Form.Field
                    name="name"
                    validators={[
                      Validators.Required('Campo obrigatório'),
                      Validators.IsFullName('Por favor, digite o nome completo')
                    ]}
                  >
                    <FloatingLabelNew label={'Nome completo'}>
                      <TextFieldNew id="text-field-medic-name" type={'text'} autoComplete="name" />
                    </FloatingLabelNew>
                  </Form.Field>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Form.Field
                    name="rg"
                    validators={[
                      Validators.Required('Campo obrigatório'),
                      Validators.IsNotOnlySpaces('Valor invalido')
                    ]}
                  >
                    <FloatingLabelNew label={'RG'}>
                      <TextFieldNew
                        id="text-field-medic-rg"
                        type={'text'}
                        kind="custom"
                        options={{ mask: 'SSSSSSSSSSSSSS' }}
                      />
                    </FloatingLabelNew>
                  </Form.Field>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Form.Field
                    name="cpf"
                    validators={[
                      Validators.Required('Campo obrigatório'),
                      Validators.IsNotOnlySpaces('Valor invalido'),
                      Validators.CpfRegex('O CPF preenchido não é válido')
                    ]}
                  >
                    <FloatingLabelNew label={'CPF'}>
                      <TextFieldNew
                        id="text-field-medic-cpf"
                        type={'text'}
                        kind="cpf"
                        onValueChange={handleCpfChange}
                      />
                    </FloatingLabelNew>
                  </Form.Field>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Form.Field
                    name="birthDate"
                    validators={[
                      Validators.Required('Campo obrigatório'),
                      Validators.IsNotOnlySpaces('Valor invalido'),
                      Validators.DateRegex('Insira uma data válida')
                    ]}
                  >
                    <FloatingLabelNew label={'Data de Nascimento'}>
                      <TextFieldNew
                        id="text-field-medic-birth"
                        kind="custom"
                        options={{ mask: '99/99/9999' }}
                        autoComplete={'bday'}
                      />
                    </FloatingLabelNew>
                  </Form.Field>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Form.Field
                    name="specialty"
                    validators={[Validators.Required('Campo obrigatório')]}
                  >
                    <FloatingLabelNew label={'Especialidade'}>
                      <EnhancedSelectNew options={specialtyOptions} />
                    </FloatingLabelNew>
                  </Form.Field>
                </Grid>
                {stateCrmSelected === 'RJ' && (
                  <Grid item xs={12}>
                    <AdviceCrmRj>
                      <Grid container>
                        <Grid item xs={1}>
                          <img src={AttentionIcon} alt="atenção" />
                        </Grid>
                        <Grid item xs={11}>
                          <span>
                            Adicione “52” na frente do CRM e remova o primeiro zero, se houver. Se o
                            seu CRM já começa com “52”, é só digitar normalmente
                          </span>
                        </Grid>
                      </Grid>
                    </AdviceCrmRj>
                  </Grid>
                )}
                <Grid item xs={12} sm={6}>
                  <Form.Field
                    name="crmState"
                    validators={[Validators.Required('Campo obrigatório')]}
                  >
                    <FloatingLabelNew label={'UF CRM'}>
                      <EnhancedSelectNew
                        options={stateOptions}
                        onChange={e => setStateCrmSelected(e.value)}
                      />
                    </FloatingLabelNew>
                  </Form.Field>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Form.Field
                    name="crm"
                    validators={[
                      Validators.Required('Campo obrigatório'),
                      Validators.IsValidCrmRj(
                        stateCrmSelected,
                        'CRM inválido. Remova o primeiro zero'
                      ),
                      Validators.IsNotOnlySpaces('Valor invalido')
                    ]}
                  >
                    <FloatingLabelNew label={'CRM'}>
                      <TextFieldNew id="text-field-medic-crn" type={'text'} />
                    </FloatingLabelNew>
                  </Form.Field>
                </Grid>
                <Grid item xs={12} md={8}>
                  <Form.Field
                    name="email"
                    validators={[
                      Validators.Required('Campo obrigatório'),
                      Validators.IsNotOnlySpaces('Valor invalido'),
                      Validators.EmailRegex('O e-mail preenchido não é válido')
                    ]}
                  >
                    <FloatingLabelNew label={'E-mail'}>
                      <TextFieldNew
                        id="text-field-medic-email"
                        type={'email'}
                        autoComplete="email"
                      />
                    </FloatingLabelNew>
                  </Form.Field>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Form.Field
                    name="phone"
                    validators={[
                      Validators.Required('Campo obrigatório'),
                      Validators.IsNotOnlySpaces('Valor invalido'),
                      Validators.PhoneRegex('O telefone preenchido não é válido')
                    ]}
                  >
                    <FloatingLabelNew label={'Telefone'}>
                      <TextFieldNew
                        id="text-field-medic-phone"
                        type={'tel'}
                        kind={'cel-phone'}
                        autoComplete="tel"
                      />
                    </FloatingLabelNew>
                  </Form.Field>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Form.Field
                    name="postalCode"
                    validators={[
                      Validators.Required('Campo obrigatório'),
                      Validators.IsNotOnlySpaces('Valor invalido')
                    ]}
                  >
                    <FloatingLabelNew label={'CEP'}>
                      <TextFieldNew
                        id="text-field-medic-cep"
                        type={'text'}
                        kind={'zip-code'}
                        autoComplete="postal-code"
                        onValueChange={handleCepChange}
                      />
                    </FloatingLabelNew>
                  </Form.Field>
                </Grid>
                <Grid item xs={12} md={5}>
                  <Form.Field
                    name="city"
                    initialValue={addressInfo.city}
                    key={cepTimestamp}
                    validators={[
                      Validators.Required('Campo obrigatório'),
                      Validators.IsNotOnlySpaces('Valor invalido')
                    ]}
                  >
                    <FloatingLabelNew label={'Cidade'}>
                      <TextFieldNew
                        id="text-field-medic-city"
                        type={'text'}
                        autoComplete="address-level2"
                      />
                    </FloatingLabelNew>
                  </Form.Field>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Form.Field
                    name="state"
                    initialValue={stateOptions.find(item => item.value === addressInfo.uf)}
                    key={cepTimestamp}
                    validators={[Validators.Required('Campo obrigatório')]}
                  >
                    <FloatingLabelNew label={'UF'}>
                      <EnhancedSelectNew options={stateOptions} />
                    </FloatingLabelNew>
                  </Form.Field>
                </Grid>

                <Grid item xs={12} md={9}>
                  <Form.Field
                    name="commercialAddress"
                    initialValue={addressInfo.streetAddress}
                    key={cepTimestamp}
                    validators={[
                      Validators.Required('Campo obrigatório'),
                      Validators.IsNotOnlySpaces('Valor invalido')
                    ]}
                  >
                    <FloatingLabelNew label={'Endereço Comercial'}>
                      <TextFieldNew
                        id="text-field-medic-address"
                        type={'text'}
                        autoComplete={'street-address'}
                      />
                    </FloatingLabelNew>
                  </Form.Field>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Form.Field
                    name="addressNumber"
                    validators={[
                      Validators.Required('Campo obrigatório'),
                      Validators.IsNotOnlySpaces('Valor invalido')
                    ]}
                  >
                    <FloatingLabelNew label={'Número'}>
                      <TextFieldNew id="text-field-medic-number" type={'text'} />
                    </FloatingLabelNew>
                  </Form.Field>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Form.Field
                    name="addressSuplement"
                    initialValue={addressInfo.complement}
                    key={cepTimestamp}
                  >
                    <FloatingLabelNew label={'Complemento'}>
                      <TextFieldNew id="text-field-medic-complement" type={'text'} />
                    </FloatingLabelNew>
                  </Form.Field>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Form.Field
                    name="addressDistrict"
                    initialValue={addressInfo.neighborhood}
                    key={cepTimestamp}
                    validators={[
                      Validators.Required('Campo obrigatório'),
                      Validators.IsNotOnlySpaces('Valor invalido')
                    ]}
                  >
                    <FloatingLabelNew label={'Bairro'}>
                      <TextFieldNew id="text-field-medic-neighborhood" type={'text'} />
                    </FloatingLabelNew>
                  </Form.Field>
                </Grid>
                <Grid item xs={12}>
                  <ButtonContainer>
                    <Button
                      id={`button-submit-medic-register`}
                      type="submit"
                      kind="primary"
                      loading={loading}
                    >
                      {pageData.sendButtonText}
                    </Button>
                  </ButtonContainer>
                </Grid>
              </Grid>
            </Form>
          </Grid>
          <Grid item xs={12} sm={4}>
            <ImageSidebarContainer>
              <div className="frame1" />
              <div className="frame2" />
              <img
                src={cosmicStaticInfo.metadata.contact_area.image.imgix_url}
                alt="contact-image"
              />
              <div className="frame3" />
              <div className="frame4" />
            </ImageSidebarContainer>
            <ContactSidebar>
              <span className="title">{cosmicStaticInfo.metadata.contact_area.title}</span>
              <span className="subtitle">{cosmicStaticInfo.metadata.contact_area.subtitle}</span>
              <a href={`tel:${cosmicInfosContato.telefoneContato}`}>
                <div className="contact">
                  <img src={iconAtendente} alt="contato" />
                  <span className="contact-title">
                    {cosmicStaticInfo.metadata.contact_area.phone_title}:
                  </span>
                  <span className="contact-number">{cosmicInfosContato.label_telefone}</span>
                </div>
              </a>
              <span className="hour-title">
                {cosmicStaticInfo.metadata.contact_area.horario_de_atendimento_titulo}
              </span>
              <div
                className="hour-description"
                dangerouslySetInnerHTML={{ __html: cosmicInfosContato.horarioAtendimento }}
              />
            </ContactSidebar>
            {/* <H2>Cadastre-se</H2>
            <div
              dangerouslySetInnerHTML={{
                __html: pageData.col2HTMLContent
              }}
            /> */}
          </Grid>
        </Grid>
        <Separator />
      </Container>
    </>
  )
}

export default DoctorRegisterNew
