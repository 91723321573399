import { InputPlaceholderCss, InputValueCss } from 'atomic/legacy/atm.typography'
import { Color, TransitionDuration } from 'atomic/legacy/obj.constants'
import {
  fieldBorderCss,
  FieldProps,
  fieldTypographyCss
} from 'atomic/legacy/obj.form/field.component.styled'
import ColorFunc from 'color'
import Select from 'react-select'
import AsyncSelect from 'react-select/async'
import AsyncCreatable from 'react-select/async-creatable'
import Creatable from 'react-select/creatable'
import styled, { css } from 'styled-components'
import { fadeIn } from '../obj.animation/animation.component.style'

const verticalPadding = 12

// https://react-select.com/styles
const selectCss = css`
  ${fieldTypographyCss};
  margin: 0;

  & .select__input > input {
    ${fieldTypographyCss};
  }

  & .select__control {
    ${fieldBorderCss};

    background-color: transparent;
    overflow: auto;

    &:hover {
      ${fieldBorderCss};
    }

    &.select__control--is-focused {
      border-color: ${props => props.theme.color.primary};
      box-shadow: none;
    }
  }

  & svg {
    fill: ${Color.GrayDark};
  }

  & .select__value-container {
    padding: ${verticalPadding}px 0 ${verticalPadding}px 0;
  }

  & .select__placeholder {
    ${InputPlaceholderCss};
  }

  & .select__menu {
    margin: 0;
    animation: ${fadeIn} ${TransitionDuration} linear forwards;
  }

  & .select__menu-list {
  }

  & .select__indicator {
    ${(props: FieldProps) => (props.disabled ? 'display: none;' : '')}
  }

  & .select__indicator-separator {
    display: none;
  }

  & .select__option {
    ${InputValueCss};
  }

  & .select__option--is-focused {
    background-color: ${props => props.theme.color.primary}20;
  }

  & .select__option--is-selected {
    background-color: ${Color.White};
    color: ${props => props.theme.color.primary};
  }

  & .select__menu-notice--no-options {
    ${InputValueCss};
  }

  & .select__single-value {
    top: ${verticalPadding * 2}px;
  }

  & .select__multi-value {
    background-color: ${props => props.theme.color.primary}20;
  }
  & .select__multi-value__label {
  }
  & .select__multi-value__remove {
    ${(props: FieldProps) => (props.disabled ? 'display: none;' : '')}
  }
  & .select__multi-value__remove:hover {
    background-color: ${ColorFunc(Color.GrayLight)
      .darken(0.2)
      .hsl()
      .string()};
  }
`

export const SelectStyled = styled(Select).attrs({ classNamePrefix: 'select' })`
  ${selectCss}
`

export const AsyncSelectStyled = styled(AsyncSelect).attrs({ classNamePrefix: 'select' })`
  ${selectCss}
`

export const CreatableSelectStyled = styled(Creatable).attrs({ classNamePrefix: 'select' })`
  ${selectCss}
`

export const AsyncCreatableSelectStyled = styled(AsyncCreatable).attrs({
  classNamePrefix: 'select'
})`
  ${selectCss}
`
