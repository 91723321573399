import { FormFieldContext, FormFieldContextState } from 'atomic/legacy/obj.form'
import * as React from 'react'
import { isNull, isNullOrUndefined } from 'util'
// tslint:disable-next-line:max-line-length
import {
  AsyncCreatableSelectStyled,
  AsyncSelectStyled,
  CreatableSelectStyled,
  SelectStyled
} from './enhanced-select.component.style'

export interface SelectOption {
  value: any
  label: string
}

interface SelectChangeAction {
  action: 'select-option' | 'remove-value' | 'pop-value' | 'clear' | 'create-option'
  option?: SelectOption
  removedValue?: SelectOption
}

interface SelectProps {
  placeholder?: string

  /** https://react-select.com/components#components */
  components?: any
  isSearchable?: boolean
  isCreatable?: boolean
  isLoading?: boolean
  isMulti?: boolean
  isDisabled?: boolean
  invalid?: boolean
  options?: SelectOption[]
  onChange?: (options: SelectOption[]) => void
  onFocus?: (event) => void
  onBlur?: (event) => void
  defaultValue?: any
  /** if you set this, then the AsyncSelect will be used */
  loadOptions?: (inputValue: string) => Promise<SelectOption[]>
  id: string
}

/**
 * This component is a `react-select` wrapper. It is integrated with the
 * Taqtile form component and with this project field style.
 *
 * To learn more about its usage, check this: https://react-select.com/home
 *
 * If you don't need it in your project, remember to remove `react-select` from `package.json`
 *
 */
export class EnhancedSelect extends React.Component<SelectProps, undefined> {
  private formFieldConsumer: FormFieldContextState

  constructor(props) {
    super(props)
  }

  render() {
    console.log(this.props)
    return (
      <FormFieldContext.Consumer>
        {(formFieldConsumer: FormFieldContextState) => {
          this.formFieldConsumer = formFieldConsumer
          const SelectComponent = this.props.isCreatable
            ? this.props.loadOptions
              ? AsyncCreatableSelectStyled
              : CreatableSelectStyled
            : this.props.loadOptions
            ? AsyncSelectStyled
            : SelectStyled

          const invalid = this.formFieldConsumer && this.formFieldConsumer.errors.length
          return (
            /** https://react-select.com/props */
            <SelectComponent
              id={this.props.id}
              isMulti={this.props.isMulti}
              isDisabled={this.props.isDisabled}
              // for styled-components
              disabled={this.props.isDisabled}
              isLoading={this.props.isLoading}
              invalid={invalid}
              // this component doesn't accept 'null' as a value
              options={isNull(this.props.options) ? undefined : this.props.options}
              onChange={this.handleChange}
              value={this.getValue()}
              cacheOptions={!isNullOrUndefined(this.props.loadOptions)}
              loadOptions={this.props.loadOptions}
              components={this.props.components}
              // tslint:disable:jsx-no-lambda
              loadingMessage={() => 'Carregando...'}
              noOptionsMessage={({ inputValue }) => {
                return inputValue.length > 0
                  ? 'Nenhuma opção foi encontrada.'
                  : 'Digite algo para buscar opções.'
              }}
              placeholder={this.props.placeholder || ''}
              onFocus={this.handleFocus}
              onBlur={this.handleBlur}
              isSearchable={this.props.isSearchable}
              defaultValue={this.props.defaultValue}
            />
          )
        }}
      </FormFieldContext.Consumer>
    )
  }

  getValue = () => {
    if (
      isNullOrUndefined(this.formFieldConsumer) ||
      isNullOrUndefined(this.formFieldConsumer.value) ||
      this.formFieldConsumer.value.length === 0
    ) {
      return undefined
    }

    // it seems that Select does some optimizations to avoid re-rendering. To
    // force a re-render here, the value is copied to make sure the value is
    // changed and then the SelectComponent is re-rendered
    const copiedValue = this.formFieldConsumer.value.slice
      ? this.formFieldConsumer.value.slice()
      : this.formFieldConsumer.value

    return copiedValue
  }

  private handleFocus = event => {
    if (this.props.onFocus) {
      this.props.onFocus(event)
    }
    this.formFieldConsumer.onFocusChange(true)
  }

  private handleBlur = event => {
    if (this.props.onBlur) {
      this.props.onBlur(event)
    }
    this.formFieldConsumer.onFocusChange(false)
  }

  private handleChange = (obj: SelectOption[], action: SelectChangeAction) => {
    if (this.props.onChange) {
      this.props.onChange(obj)
    }

    if (this.formFieldConsumer) {
      if (action.action === 'remove-value' || action.action === 'pop-value') {
        this.formFieldConsumer.onValueChange([action.removedValue], false)
      } else if (action.action === 'clear') {
        this.formFieldConsumer.onClear()
      } else if (action.action === 'create-option') {
        const option = this.props.isMulti ? [obj[obj.length - 1]] : obj
        this.formFieldConsumer.onValueChange(option, true)
      } else if (action.action === 'select-option') {
        const option = this.props.isMulti ? [action.option] : obj
        this.formFieldConsumer.onValueChange(option, true)
      }
    }
  }
}
